body {
    margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.datePickerFilter {
    overflow: visible !important;
}

.datepickerinput {
    width: 100% !important;
}

.dateInputCell {
    display: table !important;
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: .67857143em 1em;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
    width: 100% !important;
}

/*.rt-table {
    overflow: inherit !important;
}*/


.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__input-container {
    width: 100% !important;
}

.react-datepicker {
    position: absolute !important;
}

.link-button {
    color: blue;
    text-decoration: underline;
    cursor:pointer
}

#operationComment {
    width: 100%;
    resize: none;
}

.rt-table {
    /*overflow: hidden !important;*/
}

.rt-tbody {
    /*min-width: 0px !important;*/
}

.rt-thead.-filters {
    outline: none;
}

.rt-thead {
    /*min-width: 0px !important;*/
    outline: none;
}

.rt-tr-group {
    /*min-width: 0px !important;*/
}

.rt-resizable-header {
    outline: none;
}

.rt-resizer {
    width: 15px !important;
}

.vertical-scrollbar-present {
    /*width: calc(100% - 15px) !important;*/
    width: calc(100%) !important;
}

.semanticInputSuccess {
    background-color: #5cd65c !important;
}

.ui.page.dimmer {
    z-index: 1002 !important;
}

/*.ui.loader {
    z-index: 1002 !important;
}*/

/*Setting z-index of navbar to 1001 in order to display above semantic react ui loader that has z-index of 1000 */
.navMenu {
    z-index: 1001 !important;
}

.ui.active.selection.dropdown {
    z-index: 1000 !important;
}

.changesetSaveAndCancelChanges {
    vertical-align: middle !important;
    background-color: transparent !important;
    color: blue !important;
    text-decoration: none !important;
    border: none !important;
    cursor: pointer !important;
    margin-right: 0px !important;
}

.changesetSaveAndCancelChanges:hover {
    vertical-align: middle !important;
    background-color: transparent !important;
    color: gold !important;
    text-decoration: underline !important;
    border: none !important;
    cursor: pointer !important;
}

.changeSetFloatedButtons {
    margin-left: auto;
}

.resetLabelBlock {
    width: 120px !important;
    display: inline-block !important;
    text-align: right !important;
}

.errorContent {
    color: red;
}

.ReactTable .-pagination .-btn {
    color: white !important;
    background: black !important;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
    background:rgba(0,0,0,0.7) !important;
    color: #fff !important;
}

.ReactTable .-pagination .-btn[disabled] {
    opacity:.3 !important;
    cursor:default;
}
body ::-webkit-scrollbar{
    width: 20px !important;
    height: 20px !important;
}

.ui.modal {
    position: static !important;
}

/* hide up-down buttons on input type=number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.file-chooser-button {
    border: 1px solid teal;
    padding: 0.6em 2em;
    position: relative;
    color: teal;
    background: none;
}

.file-input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.upload-button {
    background: teal;
    border: 1px solid teal;
    color: #fff;
    padding: 0.6em 2em;
}